.calendarContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  font-family: Arial, Helvetica, sans-serif;
  padding-bottom: 100px;
  background-color: rgb(49, 49, 49);
  border-radius: 15px;
  padding-right: 200px;
  padding-left: 200px;
  box-shadow: 5px 5px 5px -3px #000000;
  width: 1200px;
  margin: auto;
  margin-bottom: 100px;
}

/* Заголовок календаря */
.calendarTittle {
  color: #fff;
}

/* Контейнер дней */
.daysContainer {
  display: flex;
  margin: auto;
  flex-wrap: wrap;
}

/* Слот дня */
.daySlot {
  padding: 10px;
  margin: 10px;
  text-align: center;
}

.day {
  background-color: #4b4b4b;
  border-radius: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}

/* Контейнер слотов времени */
.timeSlots {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

/* Слот времени */
.timeSlot {
  margin-top: 10px;
  padding: 10px;
  background-color: rgb(68, 182, 144);
  color: rgb(255, 255, 255);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  border: 1px solid gray;
  box-shadow: 5px 5px 5px -3px #000000;
  width: 130px;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;
}

.weekendTimeSlot {
  background-color: rgb(26, 133, 97);
}

.timeSlot.booked {
  background-color: rgb(139, 139, 139);
  cursor: not-allowed;
  opacity: 0.5;
}

.timeSlot:hover:not(.booked) {
  background-color: #9c9c9c;
  transform: scale(1.05);
}

/* Модальное окно */
.modal .form {
  position: absolute;
  display: flex;
  flex-direction: column; /* Чтобы контент внутри модального окна располагался вертикально */
  margin: auto;
  margin-top: 0px;
  background: rgb(161, 161, 161);
  border-radius: 8px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: auto; /* Добавлено для прокрутки */
  max-width: 90vw; /* Ограничение ширины */
  max-height: 80vh; /* Ограничение высоты */
  width: auto; /* Ширина по содержимому */
  min-width: 300px; /* Минимальная ширина для модального окна */
  z-index: 1001;
  padding: 20px; /* Добавлено для отступов внутри модального окна */
}

form {
  margin-top: 0px;
}

/* Оверлей модального окна */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center; /* Центрирование модального окна по вертикали */
  overflow-y: auto;
  overflow-x: hidden;
}

body.modalOpen {
  background-color: #000000;
}

/* Кнопка закрытия модального окна */
.modalClose {
  display: block;
  width: 100%;
  padding: 0 20px;
  margin-bottom: 10px;
  background: #6e6e6e;
  line-height: 40px;
  border-width: 0;
  border-radius: 20px;
  font-family: 'Roboto', sans-serif;
  margin-top: 30px;
  border-bottom: 4px solid #ffb5b5;
  color: rgb(255, 255, 255);
  font-size: 14px;
  cursor: pointer;
}

/* Форма */
form {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: auto;
  background: rgb(70, 69, 69);
  border-radius: 30px;
  padding-left: 200px;
  padding-right: 200px;
  padding-top: 100px;
  padding-bottom: 100px;
  width: 700px;
}

.formLeftDecoration,
.formRightDecoration {
  content: '';
  position: absolute;
  width: 50px;
  height: 20px;
  background: #38b394;
  border-radius: 20px;
}

.formLeftDecoration {
  bottom: 60px;
  left: -30px;
}

.formRightDecoration {
  top: 60px;
  right: -30px;
}

.formLeftDecoration:before,
.formLeftDecoration:after,
.formRightDecoration:before,
.formRightDecoration:after {
  content: '';
  position: absolute;
  width: 50px;
  height: 20px;
  border-radius: 30px;
  background: rgb(117, 117, 117);
}

.formLeftDecoration:before {
  top: -20px;
}

.formLeftDecoration:after {
  top: 20px;
  left: 10px;
}

.formRightDecoration:before {
  top: -20px;
  right: 0;
}

.formRightDecoration:after {
  top: 20px;
  right: 10px;
}

.circle {
  position: absolute;
  bottom: 80px;
  left: -55px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: rgb(116, 116, 116);
}

.formInner {
  position: relative;
  padding-left: 98px;
  border-left: 1px solid #06a17b;
  margin-top: 20px;
}

.formInner h3 {
  margin-top: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 24px;
  color: #ffffff;
}

.formInner input,
.formInner textarea {
  display: block;
  width: 90%;
  padding: 0 20px;
  margin-bottom: 10px;
  background: #e9eff6;
  line-height: 40px;
  border-width: 0;
  border-radius: 20px;
  font-family: 'Roboto', sans-serif;
}

.formInner input[type='submit'] {
  margin-top: 30px;
  width: 100%;
  background: #38b394;
  border-bottom: 4px solid #38b394;
  color: white;
  font-size: 14px;
  cursor: pointer;
}

.formInner textarea {
  resize: none;
}

.label {
  display: flex;
}

.select {
  color: #6fceb6;
  background-color: #6e6e6e;
  margin: auto;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 19px;
}

.members {
  color: white;
  text-align: center;
}

.reservDate {
  margin: 0px;
}

.reservTitle {
  margin: 0px;
}

.calendarTitle {
  color: white;
  margin-top: 80px;
}

.day {
  color: white;
  margin-bottom: 0px;
}

.cost {
  color: rgb(179, 179, 179);
  font-size: 15px;
  font-weight: 700;
}

.timePrice {
  color: #e4e4e4;
  margin: 0px;
  font-size: 12px;
  font-weight: 200;
}

.timeSvg {
  width: 40px;
  height: 40px;
  margin-right: 20px;
}

.reservTitle {
  color: white;
}

.reservDate {
  margin-top: 30px;
  display: flex;
  align-items: center;
}

.reservDateInfo {
  color: white;
  margin-left: 20px;
  font-weight: 600;
}

.dateInformTittle {
  color: #afafaf;
  font-size: 20px;
  font-weight: 700;
}

.infoDescription {
  width: 300px;
  font-size: 17px;
  margin-top: 20px;
  color: #66a797;
  font-weight: 500;
}

/* Медиазапросы для адаптивной верстки */

@media (min-width: 1400px) and (max-width: 1600px) {
  .calendarContainer {
    padding-left: 70px;
    padding-right: 70px;
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .calendarContainer {
    padding-left: 0px;
    padding-right: 0px;
  }
}

/* Для планшетов */
@media (min-width: 768px) and (max-width: 1200px) {
  .calendarContainer {
    padding-left: 50px;
    padding-right: 50px;
    width: 85%;
  }

  .after-open .modal {
    max-height: fit-content;
    margin-top: 0px;
  }

  .daysContainer {
    flex-direction: column; /* Дни отображаются в столбик */
  }

  .timeSlots {
    display: flex;
    flex-direction: row; /* Время отображается в строку */
    flex-wrap: wrap;
    justify-content: center;
  }

  .timeSlot {
    margin: 10px;
    width: auto;
    max-width: 120px;
  }

  form {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    margin-top: 0px;
  }

  .formInner {
    padding-left: 20px;
  }
}

/* Для мобильных устройств */
@media (max-width: 767px) {
  .modal {
    margin-top: 150px;
    margin-bottom: 20px;
  }

  .modal .form {
    max-width: 90vw; /* Ограничение ширины на мобильных устройствах */
    overflow-y: auto; /* Прокрутка по вертикали */
    position: fixed; /* Фиксированное позиционирование для мобильных устройств */
    top: 300px; /* Добавлено для отступа от верха экрана */
    left: 5%;
    right: 5%;
    bottom: 10%; /* Добавлено для отступа от низа экрана */
  }

  .calendarContainer {
    padding-left: 20px;
    padding-right: 20px;
    width: 80%;
  }

  .daysContainer {
    flex-direction: column; /* Дни отображаются в столбик */
    width: 70%;
  }

  .timeSlots {
    display: flex;
    flex-direction: column; /* Время отображается в столбик */
    align-items: center;
  }

  .timeSlot {
    margin: 10px;
    width: 100%; /* Время занимает всю ширину контейнера */
    max-width: 100%; /* Убираем максимальную ширину */
  }

  form {
    padding-top: 5px;
    display: block;
    padding-left: 20px;
    padding-right: 20px;
    width: 75%;
    padding-bottom: 20px;
  }

  .timeSvg {
    width: 20px;
    height: 20px;
  }

  .formInner {
    width: 80%;
    margin: auto;
    border-left: none;
    margin-top: 50px;
  }

  .reservDate {
    margin-top: 10px;
  }

  .formInner {
    padding-left: 0;
  }

  .infoDescription {
    max-width: 100%;
  }

  .reservTitle {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
