.slider {
  width: 600px;
  text-align: center;
}

.sliderSection {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  font-family: Arial, Helvetica, sans-serif;
  background-color: rgb(49, 49, 49);
  border-radius: 15px;
  box-shadow: 5px 5px 5px -3px #000000;
  width: 1600px;
  margin: auto;
  margin-top: 100px;
}

.sliderTitle {
  font-size: 24px;
}

.buttonContainer {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  margin-bottom: 100px;
  width: 800px;
}

.button {
  margin-top: 10px;
  padding: 20px 50px;
  background-color: #1a8561;
  color: rgb(255, 255, 255);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  width: 200px;
  border: 1px solid gray;
  box-shadow: 5px 5px 5px -3px #000000;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;
}

.button:hover {
  background-color: #929292;
}

.advantagesImg {
  width: 200px;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: #3b3b3b;
  padding-bottom: 23px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  outline: none;
  border-radius: 10px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.closeButton {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.commentSlide {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 600px;
  height: 300px;
}

.otherContent {
  text-align: center;
}

.sliderTitle {
  text-align: center;
  color: white;
  font-size: 30px;
  padding-top: 50px;
  margin-bottom: 80px;
}

.commentName {
  margin: 0px;
}

.commentDate {
  margin: 0px;
}

.commentInfo {
  display: flex;
  justify-content: space-between;
  padding: 10px 35px;
  margin-top: 20px;
  color: white;
}

.commentText {
  background-color: #535353;
  border-radius: 10px;
  width: 400px;
  color: white;
  margin: auto;
  padding: 10px 10px;
}

.commentBox {
  display: flex;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.commentFooter {
  display: flex;
  justify-content: space-between;
  padding: 10px 35px;
}

.commentConfirm {
  font-size: 17px;
  font-weight: 600;
  width: 190px;
  color: rgb(255, 239, 167);
}

.commentTop {
  color: rgb(209, 209, 209);
  font-size: 17px;
  font-weight: 700;
}

.commentSpan {
  font-size: 17px;
  font-weight: 500;
  color: rgb(173, 255, 180);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 5px;
  background-color: #969696;
}

.video {
  width: 100%;
  height: 800px;
}

/* Для больших экранов */
@media (min-width: 1400px) and (max-width: 1600px) {
  .sliderSection {
    width: 1400px;
  }
}

/* Для средних экранов */
@media (min-width: 1200px) and (max-width: 1400px) {
  .sliderSection {
    width: 1200px;
  }

  .video {
    width: 100%;
    height: 700px;
  }
}

/* Для планшетов */
@media (min-width: 768px) and (max-width: 1200px) {
  .sliderSection {
    flex-direction: column;
    width: 85%;
    padding: 20px;
    margin-top: 0px;
  }

  .buttonContainer {
    flex-direction: row;
    width: 100%;
  }

  .button {
    width: 100%;
    max-width: 150px;
  }

  .video {
    width: 100%;
    height: 500px;
  }
}

/* Для мобильных устройств */
@media (max-width: 767px) {
  .slider {
    width: 320px;
    text-align: center;
  }

  .video {
    width: 100%;
    height: 700px;
  }

  .sliderSection {
    flex-direction: column;
    width: 80%;
    padding: 10px;
    margin-top: 0px;
  }

  .sliderTitle {
    font-size: 24px;
    padding-top: 20px;
    margin-bottom: 40px;
  }

  .commentSlide {
    display: flex;
    flex-direction: column;
    width: 90%;
    padding: 10px;
    height: auto;
    justify-content: space-around;
  }

  .commentText {
    width: 80%;
  }

  .buttonContainer {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  .button {
    width: 100%;
    max-width: 200px;
    margin-top: 40px;
  }

  .commentFooter {
    display: block;
  }

  .commentConfirm {
    margin: auto;
    margin-bottom: 23px;
  }

  .advantagesImg {
    width: 200px;
  }
}
