.logotype {
  color: rgb(255, 255, 255);
  font-family: 'Oswald', sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  display: flex;
  align-items: center;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.instIcon {
  transition: 0.5s;
}

.instIcon:hover {
  transform: scale(1.2);
}

.instIconTik {
  transition: 0.5s;
  padding-top: 1px;
  width: 22px;
  height: 22px;
}

.instIconTik:hover {
  transform: scale(1.2);
}

.contactsList {
  display: flex;
  list-style: none;
  align-items: center;
}

.contactItem {
  margin-right: 30px;
}

.contactCity {
  margin: 0px;
  margin-right: 30px;
  color: rgb(138, 138, 138);
  padding-bottom: 6px;
}

/* Mobile styles (max-width: 767px) */
@media screen and (max-width: 767px) {
  .logotype {
    font-size: 19px;
  }

  .instIcon {
    width: 25px;
    height: 25px;
  }

  .contactsList {
    padding: 5px;
  }

  .contactItem {
    margin-right: 10px;
  }
  .contactCity {
    margin: 0px;
    margin-right: 10px;
    color: rgb(138, 138, 138);
    padding-bottom: 6px;
    font-size: 15px;
  }
  .outlast {
    padding-bottom: 5px;
  }
}

/* Tablet styles (min-width: 768px and max-width: 1398px) */
@media screen and (min-width: 768px) and (max-width: 1398px) {
  .logotype {
    font-size: 19px;
  }

  header {
    width: 100%;
  }

  .instIcon {
    width: 25px;
    height: 25px;
  }
}

/* Desktop styles (min-width: 1399px) */
@media screen and (min-width: 1399px) {
  .logotype {
    font-size: 30px;
  }

  header {
    width: 1400px;
    margin: auto;
  }

  .instIcon {
    width: 30px;
    height: 30px;
  }

  .instIconTik {
    padding-top: 2px;
    width: 25px;
    height: 25px;
  }
}
